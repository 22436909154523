import { InterestableType, type TInterestableType } from '~/globals/interest';

const SymbolInterest = Symbol('app:interests');
const createInterestsList = (
  initInterests: MaybeRef<Interest[]>,
  data?: MaybeRef<User | Room>,
  name: MaybeRef<string> = 'interest_ids',
  interestableType: MaybeRef<TInterestableType> = InterestableType.USER
) => {
  const interestsStore = useInterestsStore();
  const search = ref('');
  const searchDebounced = refDebounced(search, 200);
  const interestableMeta = computed(() => {
    const dataMeta = unref(data);
    return {
      interestableId: dataMeta?.id || 0,
      interestIds: dataMeta?.interest_ids || []
    };
  });

  const onGroupItems = (items = unref(initInterests)) => {
    const groups = useGroupBy(items, 'related_id');

    return useMap(groups?.null, item => ({
      ...item,
      items: groups[item.id] || []
    }));
  };

  const interestsGroups = computed(() => onGroupItems());

  const filteredInterestsGroupsOnlySelected = computed(() => {
    const selectIds = interestableMeta.value.interestIds;
    if (!selectIds.length) {
      return [];
    }
    const groupItems = onGroupItems(
      useFilter(unref(initInterests), item => !item.related_id || selectIds.includes(item.id))
    );
    return useFilter(groupItems, ({ items }) => !!items.length);
  });

  const onSearch = (name: string) => name.toLowerCase().includes(searchDebounced.value.toLowerCase());

  const searchResults = computed(() => {
    if (useTrim(searchDebounced.value)) {
      const searchItems = [];
      for (const item of interestsGroups.value) {
        const items = useFilter(item.items, child => onSearch(child.name));
        if (items.length) {
          searchItems.push({
            ...item,
            items
          });
        }
      }
      return searchItems;
    }
    return interestsGroups.value;
  });

  const { value, errorMessage } = useField<number[]>(() => unref(name));

  const { open } = useCreateInterestModal({});

  const openCreateModal = (relatedId: number) => {
    open({
      relatedId,
      interestableType,
      interestableId: interestableMeta.value.interestableId,
      onSuccess: (data: Interest) => {
        if (data.related_id) {
          value.value = [...value.value, data.id];
          interestsStore.onAddInterest(data, unref(interestableType));
        }
      }
    });
  };

  const onDeleteItem = (id: number) => {
    value.value = useFilter<number>(value.value, v => v !== id);
    interestsStore.onDeleteInterest(id, unref(interestableType));
  };

  const state = {
    errorMessage,
    value,
    search,
    searchResults,
    filteredInterestsGroupsOnlySelected,
    onDeleteItem,
    openCreateModal
  };
  provide<typeof state>(SymbolInterest, state);
  return state;
};

const useInterestsList = () => {
  const res = inject<ReturnType<typeof createInterestsList>>(SymbolInterest);

  if (!res) {
    throw new Error(`Could not find useInterestsList injection with symbol ${SymbolInterest.description}`);
  }
  return res;
};

export { useInterestsList, createInterestsList };
