<template>
  <div class="flex flex-col">
    <ui-loader
      :loading="pending"
      :error="error"
      static
    >
      <div class="flex lg:flex-row flex-col">
        <common-profile-settings-sidebar v-if="!(isMobile && isChildRoute)" />
        <div class="flex-1 lg:max-w-[calc(100%-360px)]">
          <ui-card
            v-if="isChildRoute"
            padding="xl"
          >
            <nuxt-page />
          </ui-card>
        </div>
      </div>
    </ui-loader>
  </div>
</template>

<script setup lang="ts">
  type Props = {
    pending?: boolean;
    error?: any;
  };
  defineProps<Props>();

  const isMobile = useIsLessThan('lg');
  const currentRouteName = useCurrentRouteName();

  const isChildRoute = computed(() => {
    return currentRouteName.value !== 'dashboard-settings';
  });
</script>
