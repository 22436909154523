const SubscriptionType = {
  Free: 1,
  Base: 2,
  Premium: 3,
  Vip: 4
} as const;

type TSubscriptionType = (typeof SubscriptionType)[keyof typeof SubscriptionType];

const DurationSubscriptionType = {
  '1Month': 1,
  '3Month': 2,
  '12Month': 3
} as const;

type TDurationSubscriptionType = (typeof DurationSubscriptionType)[keyof typeof DurationSubscriptionType];
type SubscriptionFeaturesItem = {
  label: string;
  order: number;
  unLimitLabel?: string;
  description?: string;
};
const SubscriptionFeatures: Record<string, SubscriptionFeaturesItem> = {
  profile_interest_limit: {
    label: 'Interest in Profile',
    order: 1
  },
  profile_photo_limit: {
    label: 'Profile Photo',
    unLimitLabel: 'Profile photo without restrictions',
    order: 2
  },
  room_messages_per_day_limit: {
    label: 'Room messages Per Day',
    unLimitLabel: 'No restrictions on sending messages',
    order: 3
  },
  rooms_as_member_limit: {
    label: 'Participation rooms',
    unLimitLabel: 'Participation in rooms without restrictions',
    description: '(including application stage)',
    order: 4
  },
  rooms_as_owner_limit: {
    label: 'Creating rooms',
    description: '(including idea creation stage)',
    order: 5
  },
  custom_mood: {
    label: 'Custom "mood" in your profile',
    order: 6
  },
  hide_online: {
    label: 'Hide online status',
    order: 7
  },
  custom_interest: {
    label: 'Ability to submit a custom interest in your profile for review by moderators',
    order: 8
  }
};

const SubscriptionInfo: Record<string, { colorChip: string; colorText: string }> = {
  [SubscriptionType.Vip]: {
    colorChip: 'bg-subscription-vip-bg text-subscription-vip-text',
    colorText: 'text-subscription-vip-bg'
  },
  [SubscriptionType.Premium]: {
    colorChip: 'bg-subscription-premium-bg text-subscription-premium-text',
    colorText: 'text-subscription-premium-bg'
  },
  [SubscriptionType.Base]: {
    colorChip: 'bg-subscription-base-bg text-subscription-base-text',
    colorText: 'text-subscription-free-text'
  },
  [SubscriptionType.Free]: {
    colorChip: 'bg-subscription-free-bg text-subscription-free-text',
    colorText: 'text-subscription-free-text'
  }
};

export {
  SubscriptionType,
  SubscriptionInfo,
  SubscriptionFeatures,
  DurationSubscriptionType,
  type TSubscriptionType,
  type TDurationSubscriptionType,
  type SubscriptionFeaturesItem
};
