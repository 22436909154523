<template>
  <component :is="tag">
    <slot
      v-if="$slots.default"
      :toggle="toggle"
      :select="select"
      :is-selected="isSelected"
    />
    <template v-else>
      <ui-expansion-panel-title
        :title-tag="titleTag"
        :hide-actions="hideActions"
        :disabled="disabled"
        :size="size"
      >
        <template #default="slotProps">
          <slot
            name="title"
            v-bind="slotProps"
          >
            {{ title }}
          </slot>
        </template>
        <template #after-action="slotProps">
          <slot
            name="after-action-title"
            v-bind="slotProps"
          />
        </template>
      </ui-expansion-panel-title>
      <transition-expand>
        <ui-expansion-panel-body
          :id="bodyId"
          :size="size"
        >
          <slot name="body">
            {{ body }}
          </slot>
        </ui-expansion-panel-body>
      </transition-expand>
    </template>
  </component>
</template>

<script setup lang="ts">
  const props = withDefaults(defineProps<Omit<GroupItemProps, 'type' | 'expandIcon'>>(), {
    tag: 'div'
  });

  const { toggle, isSelected, select } = useGroupItem(props);
</script>
