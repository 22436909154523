<template>
  <div class="grid md:gap-7 sm:gap-5 gap-2.5 lg:grid-cols-3 grid-cols-3 2xl:grid-cols-7">
    <common-profile-user-card
      :user="user"
      :is-public="isPublic"
      class="col-span-3"
      :class="{ '2xl:col-span-3 md:col-span-2': !isPublic }"
    />

    <common-profile-coin-balance
      v-if="!isPublic"
      :balance="props.user?.balance || 0"
      class="md:col-span-1 col-span-3"
    />

    <div
      class="grid grid-cols-3 md:gap-7 sm:gap-5 gap-2.5 col-span-3"
      :class="isPublic ? '2xl:col-span-4' : '2xl:col-span-3 md:col-span-3'"
    >
      <common-profile-card-counter
        v-for="(item, i) in profileInfoConfig"
        :key="i"
        :item="item"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  type Props = {
    user: User;
    isPublic?: boolean;
  };

  const props = defineProps<Props>();

  const { formatDate, DateFormats } = useFormatDate();

  const profileInfoConfig = computed<ProfileInfoItem[]>(() => {
    const date = formatDate(props.user.created_at, DateFormats.Pretty) as string;

    return [
      {
        icon: 'calendar_month',
        name: 'Registration Date',
        value: date || '-'
      },
      {
        icon: 'sms',
        name: 'Messages Quantity',
        value: props.user.messages_count || 0
      },
      {
        icon: 'schedule',
        name: 'Number of days of continuous use',
        value: props.user.profile?.online_days || 0 + ' days'
      }
    ];
  });
</script>
