<template>
  <div class="relative">
    <template v-if="items.length">
      <div
        v-for="(item, i) in items"
        :key="keyField ? item[keyField] : i"
        :class="wrapItemClasses"
      >
        <slot
          name="item"
          :item="item"
        />
      </div>
      <div
        v-if="!isLastPage"
        class="flex justify-center relative"
        :class="loadMoreClass"
      >
        <ui-button
          ref="btnLoadMoreRef"
          :loading="loading"
          color="primary"
          size="sm"
          text="Load More"
          @click="onLoadMore"
        />
      </div>
    </template>
    <ui-loader
      static
      :loading="loading && showLoader"
    >
      <slot
        v-if="!loading && !items.length"
        name="empty"
      />
    </ui-loader>
  </div>
</template>

<script setup lang="ts">
  type Props = {
    items: Array<any>;
    showLoader?: boolean;
    isLastPage?: boolean;
    loading?: boolean;
    keyField?: string;
    notFoundTitle?: string;
    notFoundMessage?: string;
    wrapItemClasses?: ClassesType;
    loadMoreClass?: ClassesType;
  };

  const props = withDefaults(defineProps<Props>(), {
    wrapItemClasses: 'py-1.5'
  });

  const emit = defineEmits(['loadMore']);

  const btnLoadMoreRef = ref();

  const onLoadMore = () => {
    emit('loadMore');
  };

  const { pause, isActive, resume } = useIntersectionObserver(btnLoadMoreRef, ([{ isIntersecting }]) => {
    if (isIntersecting && !props.loading) {
      onLoadMore();
    }
  });

  watch(
    () => props.isLastPage,
    (val: boolean) => {
      if (val) {
        pause();
      } else if (!isActive.value) {
        resume();
      }
    }
  );
</script>
