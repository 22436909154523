<template>
  <ui-card class="flex sm:gap-5 gap-2.5">
    <common-avatar-profile
      v-if="isPublic"
      :key="user.avatar_url"
      is-public
      class="justify-self-center shrink-0"
      :user="user"
      :subscription="user.subscription_plan"
    />
    <common-avatar-update
      v-else
      class="justify-self-center shrink-0"
    />
    <div class="flex flex-col sm:gap-5 gap-2.5">
      <common-profile-user-information
        :user="user"
        :is-editable="!isPublic"
      />
      <div
        v-if="mood"
        class="flex items-center gap-4"
      >
        <ui-typography
          variant="h4"
          class="text-primary truncate capitalize sm:max-w-52 max-w-32"
        >
          {{ isPublic ? mood.text : $t('Today I Am') }}
        </ui-typography>

        <ui-parts-mood-status
          v-if="isPublic"
          class="shrink-0"
          :mood="mood"
          hide-text
        />
        <common-profile-change-mood-status
          v-else
          :mood="mood"
        />
      </div>
      <common-forms-profile-toggle-show-online v-if="!isPublic" />
    </div>
  </ui-card>
</template>

<script setup lang="ts">
  type Props = {
    user: User;
    isPublic?: boolean;
  };

  const props = defineProps<Props>();

  const mood = computed<Mood | null>(() => props.user?.mood || null);
</script>
