<template>
  <component
    :is="tag"
    class="expansion-panels"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
  const props = withDefaults(defineProps<GroupProps>(), {
    tag: 'div'
  });

  useGroup(props);
</script>
