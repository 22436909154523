<template>
  <div class="preloader flex gap-3">
    <span class="bg-gray" />
    <span class="bg-body" />
    <span class="bg-body-circle" />
    <span class="bg-primary" />
  </div>
</template>
<style lang="scss" scoped>
  .preloader > span {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 100%;
    transform: scale(0);
    animation: scaling 2.5s ease-in-out infinite;
    @apply bg-gray;

    &:nth-child(0) {
      animation-delay: 0s;
    }
    &:nth-child(1) {
      animation-delay: 0.2s;
    }
    &:nth-child(2) {
      animation-delay: 0.4s;
    }
    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }

  @keyframes scaling {
    0%,
    100% {
      transform: scale(0.2);
      @apply bg-primary;
    }
    40% {
      transform: scale(1);
      @apply bg-body-circle;
    }
    50% {
      transform: scale(1);
      @apply bg-gray;
    }
  }
</style>
