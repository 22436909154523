const storageKey = 'avatars';
const useGetDefaultAvatars = async () => {
  const apiRoutes = useApiRoutes();

  const { pending, error, data, refresh } = await useCacheAsyncData<Attachment[]>(
    storageKey,
    async () => {
      const res = await apiRoutes.attachments.defaultAvatars();

      return res._data.data;
    },
    {
      default: () => []
    }
  );

  const avatars = computed(() => {
    return useMap(data.value || [], (image: DefaultAttachment) => ({
      ...image,
      is_default: true
    }));
  });

  return {
    avatars,
    pending,
    error,
    refresh
  };
};

export default useGetDefaultAvatars;
