<template>
  <div
    class="relative"
    :class="{ 'mb-4': !isPublic && subscription }"
  >
    <slot>
      <ui-avatar
        v-if="user"
        :rounded="subscription ? 'full' : 'sm'"
        :user-name="user.username"
        :src="user.avatar_url"
      />
    </slot>
    <template v-if="subscription">
      <ui-chip
        v-if="showChip"
        variant="custom"
        :class="colors.colorChip"
        size="xs"
        rounded="md"
        icon="star"
        class="absolute top-0 -translate-y-1/2 left-1/2 -translate-x-1/2 w-6 h-6"
      />
      <ui-chip
        v-if="!isPublic"
        variant="custom"
        :class="colors.colorChip"
        size="custom"
        class="md:py-1.5 py-1 md:px-4 px-2 absolute bottom-0 translate-y-1/2 right-1/2 translate-x-1/2"
        rounded="md"
      >
        <span class="flex truncate max-w-36 md:text-sm text-[10px]">
          {{ subscription.name }}
        </span>
      </ui-chip>
    </template>
  </div>
</template>

<script setup lang="ts">
  import { SubscriptionInfo, SubscriptionType } from '~/globals/subscriptions';
  const props = defineProps<{
    isPublic?: boolean;
    user: User;
    subscription?: SubscriptionPlan;
  }>();
  const showChip = computed(() =>
    useIncludes([SubscriptionType.Premium, SubscriptionType.Vip], props.subscription?.id)
  );
  const colors = computed(
    () => SubscriptionInfo[props.subscription?.id || SubscriptionType.Free] || SubscriptionInfo[SubscriptionType.Free]
  );
</script>
