<template>
  <v-dropdown
    :auto-size="$viewport.isLessThan('desktop')"
    placement="right"
    :distance="-66"
    :skidding="-30"
  >
    <div class="relative">
      <ui-parts-mood-status
        :mood="mood"
        hide-text
        class="cursor-pointer"
      />
      <span
        v-if="loading"
        class="absolute inset-0 flex items-center justify-center"
      >
        <ui-spinner />
      </span>
    </div>
    <template #popper="{ hide }">
      <ul class="p-2.5 flex gap-2.5 flex-col">
        <ui-parts-mood-status
          v-for="mood in moods"
          :key="mood.id"
          class="cursor-pointer"
          :mood="mood"
          tag="li"
          @click="
            onSelect(mood);
            hide();
          "
        />
      </ul>
    </template>
  </v-dropdown>
</template>

<script setup lang="ts">
  type Props = { mood: Mood };
  defineProps<Props>();

  const apiRoutes = useApiRoutes();
  const { onUpdateUser } = useAuthUser();
  const { moods } = await useGetMoods();
  const { loading, handler } = useHandlerErrorAndLoadingClient();

  const onSelect = async (mood: Mood) => {
    const { isError, data } = await handler(async () => await apiRoutes.profile.update({ mood_id: mood.id }));
    if (!isError) {
      onUpdateUser(data);
    }
  };
</script>
