const SymbolModal = Symbol('app:modal');

type StateModal = {
  setVal(val: boolean): void;
  onClose(): void;
  onSuccess(...args: any): void;
  onReject(): void;
};

type FnEmit = (name: 'update:modelValue' | 'success' | any, ...args: any[]) => void;

const getBaseEmit = (emit?: FnEmit) => {
  const vm = getCurrentInstance();
  return emit || vm?.emit || (() => {});
};
const getStateModal = (emit: FnEmit): StateModal => {
  const setVal = (val: boolean) => {
    emit('update:modelValue', val);
  };
  const onClose = () => {
    setVal(false);
  };
  const onSuccess = (...args: any) => {
    emit('success', ...args);
    onClose();
  };

  const onReject = () => {
    emit('reject');
    onClose();
  };

  return {
    setVal,
    onSuccess,
    onReject,
    onClose
  };
};

const createModalState = (emit?: FnEmit, providerKey: symbol | string = SymbolModal) => {
  const state = getStateModal(getBaseEmit(emit));
  provide<StateModal>(providerKey, state);

  return state;
};

const useModalState = (providerKey: symbol | string = SymbolModal) => {
  const modalState = inject<StateModal>(providerKey);
  if (!modalState) {
    return getStateModal(getBaseEmit());
  }
  return modalState;
};

export { useModalState, createModalState };
