<template>
  <ui-typography
    :variant="variant"
    :tag="tag"
    :class="classes"
  >
    {{ statusLabel }}
  </ui-typography>
</template>

<script setup lang="ts">
  import type { TRoomUserStatus } from '~/globals/rooms';

  type Props = {
    status: TRoomUserStatus;
    variant?: KVariantsTypography;
    tag?: keyof HTMLElementTagNameMap;
    classes?: ClassesType;
  };

  const props = withDefaults(defineProps<Props>(), {
    variant: 'small',
    classes: 'text-primary-hover'
  });

  const statusLabel = computed(() => {
    return {
      [RoomUserStatus.Owner]: 'Owner',
      [RoomUserStatus.Admin]: 'Admin',
      [RoomUserStatus.Member]: 'Member',
      [RoomUserStatus.Waiting]: 'Waiting'
    }[props.status];
  });
</script>
