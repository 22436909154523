<template>
  <ui-container no-gutters-x>
    <div v-if="roomStore.room">
      <div class="grid grid-cols-1">
        <div class="grid grid-cols-12 gap-2.5 mb-2.5">
          <div class="col-span-2">
            <ui-back-link is-small />
          </div>
          <common-room-main-info
            :room="roomStore.room"
            class="col-span-8"
          />
        </div>
        <ui-card class="grid xl:grid-cols-2 grid-cols-1 gap-7">
          <ui-menu-list
            :items="menuItems"
            class="relative z-[1] flex-col items-stretch max-w-96 mx-auto w-full divide-y divide-card-placeholder-border"
          />
        </ui-card>
      </div>
    </div>
  </ui-container>
</template>

<script setup lang="ts">
  const roomStore = useRoomStore();
  const { routerReplace } = useRouterLocale();
  if (!(roomStore.userStatus.owner || roomStore.userPermission.UpdateRoomDetails)) {
    await routerReplace({
      name: 'dashboard-rooms-roomId',
      params: { roomId: roomStore.roomId }
    });
  }

  const defaultLinkProps: NavLink['linkProps'] = {
    variant: 'link',
    iconExtend: true,
    size: 'md',
    hideSpace: true
  };
  const menuItems = computed(() => {
    const menu: NavLink[] = [
      {
        itemKey: 'generalSettings',
        linkProps: {
          ...defaultLinkProps,
          iconLeft: 'settings',
          text: 'General Settings',
          to: {
            name: 'dashboard-rooms-roomId-edit',
            params: { roomId: roomStore.roomId }
          }
        }
      }
    ];

    if (roomStore.userStatus.owner) {
      menu.push({
        itemKey: 'administrators',
        liClass: 'pt-2.5',
        linkProps: {
          ...defaultLinkProps,
          text: 'Administrators',
          iconLeft: 'manage_accounts',
          to: {
            name: 'dashboard-rooms-roomId-admins',
            params: { roomId: roomStore.roomId }
          }
        }
      });
    }

    return menu;
  });

  useSeoMeta({
    title: 'Settings'
  });
</script>
