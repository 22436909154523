<template>
  <div class="grid gap-2.5">
    <slot />
    <common-profile-interests-list-wrapper
      v-for="(interestsGroup, index) in filteredInterestsGroupsOnlySelected"
      :key="index"
      :group-name="interestsGroup.name"
    >
      <ui-chip
        v-for="interest in interestsGroup.items"
        :key="interest.id"
        rounded="xs"
        :text="interest.name"
        variant="gray"
      />
    </common-profile-interests-list-wrapper>
  </div>
</template>

<script setup lang="ts">
  type Props = {
    data: Interest[];
    user: User;
  };

  const props = defineProps<Props>();

  const { filteredInterestsGroupsOnlySelected } = createInterestsList(useProp(props, 'data'), useProp(props, 'user'));
</script>
