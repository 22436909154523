<template>
  <div
    v-if="isSelected"
    class="expansion-panel-text"
    :class="'expansion-panel-text--' + size"
  >
    <div class="expansion-panel-text__wrapper">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
  defineProps<GroupItemBodyProps>();

  const { isSelected } = useGroupItemState();
</script>
