<template>
  <div
    class="flex flex-col gap-2.5"
    :class="{ 'sm:items-center': centered }"
  >
    <div class="flex gap-4 overflow-hidden">
      <ui-typography
        variant="h3"
        tag="h3"
        class="truncate"
        :text="userName"
      />
      <ui-button
        v-if="isEditable"
        icon-left="edit"
        size="xs-icon"
        variant="primary-light"
        :to="{ name: 'dashboard-settings-general' }"
        class="shrink-0"
      />
    </div>

    <ul
      v-if="userInfo.length"
      class="inline-flex flex-wrap gap-x-4 gap-y-1"
      :class="{ 'justify-center': centered }"
    >
      <li
        v-for="(item, i) in userInfo"
        :key="i"
        class="text-center sm:text-base text-sm relative after:content-[''] after:absolute after:w-1 after:h-1 after:bg-black after:rounded-full after:top-2 after:-right-2.5 last:after:content-none"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
  type Props = { user: User; centered?: boolean; isEditable?: boolean };
  const props = defineProps<Props>();
  const onCalculateAge = useCalculateAge();
  const userInfo = computed(() => {
    const age = onCalculateAge(props.user?.profile?.dob);
    const ageValue = age ? age + ' year old' : null;
    const gender = props.user?.profile?.gender;
    const genderValue = gender ? GenderSelect[gender]?.label : null;
    return useFilter([ageValue, props.user?.profile?.location, genderValue], Boolean);
  });
  const userName = computed(() => getUserFullName(props.user));
</script>
