<template>
  <ui-loader loading />
</template>

<script setup lang="ts">
  import type { TSocialAuthProvider } from '~/globals/auth';

  definePageMeta({
    validate(route) {
      return Object.values(SocialAuthProvider).includes(route.params.provider);
    }
  });

  if (IS_BROWSER) {
    const route = useRoute();
    const apiRoutes = useApiRoutes();
    const { handler } = useHandlerErrorAndLoadingClient();
    let token: string | null = '';
    if (route.query?.access_token) {
      token = route.query.access_token as string;
    } else {
      const hash = route.hash.replace('#', '');
      token = new URLSearchParams(hash).get('access_token');
    }
    if (token) {
      const { isError, data } = await handler(
        apiRoutes.auth.socialLogin({
          social_provider: route.params.provider as TSocialAuthProvider,
          social_token: token
        })
      );
      if (isError) {
        globalThis.opener.postMessage({
          isLoggedIn: false,
          authError: data,
          oauth: true
        });
      } else if (globalThis.opener) {
        globalThis.opener.postMessage({
          isLoggedIn: true,
          authData: data,
          oauth: true
        });
      }
      globalThis.close();
    } else {
      globalThis.opener.postMessage({
        isLoggedIn: false,
        authError: 'Invalid token',
        oauth: true
      });
      globalThis.close();
    }
  }
</script>
