<template>
  <ui-alert
    type="error"
    variant="ghost"
    class="justify-center"
  >
    <template #default>
      {{ $t('We recommend that you') }}
      <ui-button
        :to="{ name: 'dashboard-setup-profile' }"
        hide-space
        variant="link"
        underline="base"
        size="none"
      >
        {{ $t('fill out your profile') }}
      </ui-button>
      !
    </template>
    <template #btn-close="{ onClose }">
      <ui-button
        icon-left="close"
        variant="secondary"
        class="border-transparent"
        size="xs-icon"
        rounded="full"
        @click="onClose"
      />
    </template>
  </ui-alert>
</template>
