<template>
  <component
    :is="tag"
    :class="classes"
    :data-ui="active ? 'active' : ''"
  >
    <ui-error-boundary :error="error">
      <slot />
      <ui-loader
        v-if="loading"
        :loading="loading"
      />
    </ui-error-boundary>
  </component>
</template>
<script setup lang="ts">
  import { cva, type VariantProps } from 'class-variance-authority';

  const cardClass = cva('border', {
    variants: {
      padding: {
        sm: 'p-2.5',
        md: 'p-3 lg:p-5',
        xl: 'p-5 lg:p-7',
        '2xl': 'p-2.5 pt-5 lg:py-12 lg:px-7',
        custom: ''
      },
      color: {
        base: 'bg-card border-card',
        placeholder:
          'bg-card-placeholder border-card-placeholder-border text-card-placeholder-active data-active:border-card-placeholder-active',
        notify:
          'bg-card-notify border-card-notify-border data-active:border-card-notify-active-border data-active:bg-card-notify-active'
      },
      rounded: {
        none: '',
        sm: 'rounded-sm',
        md: 'rounded',
        xl: 'rounded-xl'
      }
    }
  });

  type CardClassVariants = VariantProps<typeof cardClass>;

  type Props = {
    padding?: CardClassVariants['padding'];
    tag?: keyof HTMLElementTagNameMap;
    loading?: boolean;
    active?: boolean;
    error?: any;
    color?: CardClassVariants['color'];
    rounded?: CardClassVariants['rounded'];
  };

  const props = withDefaults(defineProps<Props>(), {
    padding: 'md',
    tag: 'div',
    color: 'base',
    borderColor: 'none',
    rounded: 'xl'
  });

  const classes = computed(() => {
    return cardClass({
      padding: props.padding,
      color: props.color,
      rounded: props.rounded
    });
  });
</script>
