<template>
  <ui-wrap-modal v-bind="$attrs">
    <ui-typography
      v-if="title"
      variant="h3"
      class="mb-1"
    >
      {{ title }}
    </ui-typography>
    <ui-typography v-if="body">
      {{ body }}
    </ui-typography>
    <div class="mt-4 flex gap-3">
      <ui-button
        full
        :text="rejectText"
        variant="secondary-outline"
        @click="onReject()"
      />
      <ui-button
        full
        :text="confirmText"
        :loading="loading"
        @click="onConfirm"
      />
    </div>
  </ui-wrap-modal>
</template>

<script setup lang="ts">
  type Props = {
    title?: string;
    body?: string;
    rejectText?: string;
    confirmText?: string;
    confirmAction?(): Promise<boolean>;
  };
  const props = withDefaults(defineProps<Props>(), {
    rejectText: 'Cancel',
    confirmText: 'Save'
  });
  const emit = defineEmits(['update:modelValue', 'reject', 'success']);
  const { onSuccess, onReject } = createModalState(emit);
  const { loading, handler } = useHandlerErrorAndLoadingClient();

  const onConfirm = async () => {
    if (props.confirmAction) {
      const isConfirm = await handler(props.confirmAction());
      if (!isConfirm) {
        return;
      }
    }
    onSuccess();
  };
</script>
