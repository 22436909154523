<template>
  <ui-wrap-modal
    v-bind="$attrs"
    size="md"
  >
    <div class="grid grid-cols-1 gap-2.5 sm:gap-5 justify-center">
      <ui-typography
        variant="h3"
        tag="h3"
        class="text-center"
        text="Chose Your Photo"
      />

      <div class="grid grid-cols-1 gap-2.5 sm:gap-5 justify-center">
        <ui-loader
          static
          :loading="pending"
          :error="error"
        >
          <div
            class="max-w-full grid gap-5 relative sm:grid-cols-[repeat(auto-fill,_minmax(125px,_1fr))] grid-cols-[repeat(auto-fill,_minmax(80px,_1fr))]"
          >
            <common-avatar-images-list-item
              v-for="(avatar, index) in fullAvatarsList"
              :key="index"
              :image="avatar.url"
              :is-checked="checkedAvatar ? avatar.url === checkedAvatar.url : avatar.is_checked"
              @click="onChooseAvatar(avatar)"
            />
          </div>
        </ui-loader>

        <div class="mx-auto">
          <ui-fields-file-validate
            name="avatar"
            hide-preview
            btn-add-text="Upload Your Photo"
            @select="onSelect({ file: $event, avatarProperties: undefined })"
          />
        </div>

        <ui-button
          variant="primary"
          text="Update"
          :loading="loading"
          @click="onUploadAvatar"
        />
      </div>
    </div>
  </ui-wrap-modal>
</template>

<script setup lang="ts">
  type AvatarData = {
    url: User['avatar_url'];
    originUrl: User['profile']['original_avatar_url'];
    avatarProperties: AvatarProperties;
  };

  type FileListItem = Partial<AvatarData & Attachment & DefaultAttachment>;

  type Props = {
    avatar?: AvatarData;
  };
  const props = defineProps<Props>();
  const emit = defineEmits(['update:modelValue', 'select', 'success']);

  const { onClose, onSuccess } = createModalState(emit);

  const apiRoutes = useApiRoutes();
  const checkedAvatar = ref<FileListItem>();
  const { avatars: defaultAvatars } = await useGetDefaultAvatars();

  const {
    data: attachments,
    pending,
    error
  } = useLazyAsyncData<Attachment[]>('attachments', async () => {
    const res = await apiRoutes.attachments.index();

    return res._data.data;
  });

  const fullAvatarsList = computed(() => {
    let list: FileListItem[] = [...defaultAvatars.value, ...(attachments.value || [])];

    const avatarUrl = props.avatar?.url;

    if (avatarUrl) {
      list = useMap(list, avatar => ({
        ...avatar,
        is_checked: avatar.is_default && avatar.path && avatarUrl.includes(avatar.path)
      })) as FileListItem[];

      if (!list.some(avatar => avatar.is_checked)) {
        list.unshift({
          ...props.avatar,
          is_checked: true
        });
      }
    }

    return list.sort((a, b) => Number(b.is_checked) - Number(a.is_checked));
  });

  const onSelect = (data: CropImageProps) => {
    if (data.file) {
      emit('select', data);
      onClose();
    }
  };
  const { handler, loading } = useHandlerErrorAndLoadingClient();

  const onChooseAvatar = (avatar: FileListItem) => {
    if (avatar.is_default) {
      checkedAvatar.value = avatar;
      return;
    }
    onSelect({
      file: avatar.originUrl || avatar.url || '',
      avatarProperties: avatar.avatarProperties
    });
  };

  const onUploadAvatar = async () => {
    if (checkedAvatar.value) {
      const data = { avatar_path: checkedAvatar.value.path };
      const res = await handler(apiRoutes.profile.update(data));
      if (res.isError) {
        return;
      }
      onSuccess(res.data);
    }
    onClose();
  };
</script>
