<template>
  <ui-error-boundary
    :error="error"
    :classes="errorWrapClasses"
  >
    <div
      v-if="loading"
      class="inset-0 min-w-10 max-h-full flex items-center justify-center z-20"
      :class="[classes, wrapClasses, screenClasses]"
    >
      <ui-overlay
        v-if="!hideOverlay"
        :opacity="opacityOverlay"
        :fixed="fixed"
        :class="[overlayClasses, screenClasses]"
        class="z-0"
      />
      <slot name="loader">
        <ui-preloader class="relative z-10" />
      </slot>
    </div>
    <slot
      v-else-if="showNotFound"
      name="content-empty"
    >
      <ui-error-box
        :title="contentEmptyTitle"
        :description="contentEmptyMessage"
        :hide-image="contentEmptyHideImage"
        :class="contentEmptyClass"
      >
        <template #error-img>
          <slot name="content-empty-img" />
        </template>
        <template #default>
          <slot name="content-empty-slot" />
        </template>
      </ui-error-box>
    </slot>
    <slot v-else-if="!staticSlot" />
    <slot v-if="staticSlot && !showNotFound" />
  </ui-error-boundary>
</template>
<script lang="ts" setup>
  const props = withDefaults(defineProps<LoaderProps>(), {
    overlayClasses: 'text-card',
    staticClasses: 'h-60',
    opacityOverlay: 0
  });
  const classes = computed(() => {
    if (props.static) {
      return ['w-full relative', props.staticClasses];
    }
    return props.fixed ? 'fixed' : 'absolute';
  });

  const screenClasses = computed(() => {
    if (props.fullScreen) {
      return 'w-dvw h-dvh';
    }
    return '';
  });
</script>
