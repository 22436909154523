<template>
  <div class="relative flex flex-1 max-w-full">
    <ui-loader
      :loading="pending || !roomStore.roomId"
      :error="error"
    >
      <NuxtPage />
    </ui-loader>
  </div>
</template>

<script setup lang="ts">
  definePageMeta({
    layout: 'room'
  });
  const roomStore = useRoomStore();
  const route = useRoute('dashboard-rooms-roomId');
  const { onlyPublicRoom } = useCurrentRoomPermissions();

  const { error, pending } = await useAsyncData('room-' + route.params.roomId, async () => {
    return await roomStore.fetchRoom(Number(route.params.roomId));
  });

  if (onlyPublicRoom.value) {
    await useRoomRedirect();
  }

  onBeforeRouteLeave((to, from, next) => {
    if (to.params.roomId !== from.params.roomId) {
      roomStore.reset();
    }
    return next();
  });

  useHead({
    templateParams: {
      parentName: 'Room'
    },
    title: roomStore.room?.name,
    titleTemplate: '%s %separator %parentName %separator %siteName'
  });
</script>
