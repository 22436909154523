<template>
  <common-avatar-profile
    v-if="user"
    class="relative group cursor-pointer"
    :user="user"
    :subscription="user.subscription_plan"
    @click="openModal()"
  >
    <ui-avatar
      v-if="user"
      :key="user.avatar_url"
      rounded="sm"
      :user-name="user.username"
      :src="user.avatar_url"
      :size="size"
    >
      <div
        class="absolute bg-black opacity-35 group-hover:visible invisible inset-0 transition ease-in-out duration-300"
      />
      <ui-icon
        name="edit"
        class="text-white text-3xl absolute group-hover:visible invisible inset-0 transition ease-in-out duration-300"
      />
    </ui-avatar>
  </common-avatar-profile>
</template>

<script setup lang="ts">
  import type { AvatarCssProps } from '~/components/ui/Avatar/styles';

  type Props = {
    size?: AvatarCssProps['size'];
  };

  defineProps<Props>();

  const user = useSanctumUser<User>();
  const { openModal } = useUpdateAvatar();
</script>
