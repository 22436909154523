<template>
  <div
    v-if="items.length"
    class="flex flex-wrap gap-2.5"
  >
    <ui-button
      v-for="(item, i) in items"
      :key="i"
      target="_blank"
      class="p-1"
      :href="item.href"
      icon-left-type="custom"
      size="lg-icon"
      :icon-left="item.icon"
      icon-left-filled
      variant="secondary-outline"
    />
  </div>
</template>

<script setup lang="ts">
  type SocialLinksItemType = {
    icon: IconName;
    href?: string;
  };

  type Props = {
    links: SocialLinks;
  };

  const props = defineProps<Props>();

  const items = computed<SocialLinksItemType[]>(() => {
    const socialLinks = [
      {
        icon: 'facebook',
        href: props.links?.facebook
      },
      {
        icon: 'linkedin',
        href: props.links?.linkedIn
      },
      {
        icon: 'twitterx',
        href: props.links?.twitter
      },
      {
        icon: 'instagram',
        href: props.links?.instagram
      }
    ];

    return useFilter(socialLinks, 'href');
  });
</script>
