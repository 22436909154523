<template>
  <div class="w-full">
    <ui-loader
      :loading="pending"
      :error="error"
      static
    >
      <div
        v-if="user"
        class="grid grid-cols-1 gap-2.5 sm:gap-5 lg:gap-7"
      >
        <common-profile-empty-user-data-alert v-if="!isValidUser" />
        <common-profile-main-info :user="user" />
        <common-profile-media-section />
        <lazy-common-profile-additional-info
          :user="user"
          :interests="interestsStore.profilePrivateInterests"
        />
      </div>
    </ui-loader>
  </div>
</template>

<script setup lang="ts">
  definePageMeta({
    layout: 'room'
  });

  const apiRoutes = useApiRoutes();
  const user = useSanctumUser<User>();
  const interestsStore = useInterestsStore();

  const { pending, error } = await useAsyncData(async () => {
    const [profile] = await Promise.all([
      apiRoutes.profile.show(),
      interestsStore.fetchDefaultInterests(),
      interestsStore.fetchProfileInterests()
    ]);
    user.value = profile?._data.data;
    return true;
  });
  const isValidUser = useValidUser();

  useSeoMeta({
    title: 'Profile'
  });
</script>
