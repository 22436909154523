<template>
  <video
    ref="trackRef"
    class="w-full h-full aspect-video absolute inset-0 object-cover"
    autoplay
    playsInline
    :class="{
      '-scale-x-100 scale-y-100': mirror
    }"
  />
</template>

<script setup lang="ts">
  import type JitsiRemoteTrack from 'modules/RTC/JitsiRemoteTrack';
  import type JitsiLocalTrack from 'modules/RTC/JitsiLocalTrack';

  type Props = {
    track: JitsiRemoteTrack | JitsiLocalTrack;
    isMirror?: boolean;
    autoMirror?: boolean;
    forceUpdateMount?: boolean;
  };
  const props = defineProps<Props>();
  const meetStore = useMeetStore();
  const trackRef = ref();
  const mirror = computed(() => {
    if (props.autoMirror) {
      return props.track.isLocal() && props.track.getVideoType() !== 'desktop' ? meetStore.localVideoMirror : false;
    }
    return props.isMirror;
  });

  onMounted(() => {
    props.track.attach(trackRef.value);
  });

  onBeforeUnmount(() => {
    props.track.detach(trackRef.value);
  });

  defineExpose({
    trackRef,
    mirror
  });
</script>
