<template>
  <ui-card
    padding="custom"
    rounded="sm"
    color="placeholder"
    class="aspect-square relative overflow-hidden cursor-pointer border-transparent hover:border-primary focus:border-primary"
    :loading="loading"
  >
    <template v-if="private">
      <ui-button
        variant="error"
        icon-left="delete"
        size="xs-icon"
        class="absolute sm:top-2.5 sm:left-2.5 top-2 left-2"
        @click.stop="onRemoveAttach"
      />
      <div class="absolute sm:bottom-2.5 bottom-2 right-1/2 translate-x-1/2 w-full flex justify-center">
        <common-profile-media-status :is-public="!!attachment.is_public" />
      </div>
    </template>
    <ui-image
      :src="attachment?.url"
      :alt="attachment?.name"
      class="w-full object-cover h-full"
    />
  </ui-card>
</template>

<script setup lang="ts">
  type Props = { attachment: Attachment; private?: boolean };
  const props = defineProps<Props>();
  const emit = defineEmits(['remove']);
  const apiRoutes = useApiRoutes();

  const { loading, handler } = useHandlerErrorAndLoadingClient();
  const onRemoveAttach = async () => {
    const { isError } = await handler(async () => await apiRoutes.attachments.delete(props.attachment.id));
    if (!isError) {
      emit('remove', props.attachment.id);
    }
  };
</script>
