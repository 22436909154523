const useUpdateAvatar = () => {
  const { user, onUpdateUser } = useAuthUser();
  const openModal = (withList = false) => {
    if (user.value) {
      const originalAvatarUrl = user.value.profile?.original_avatar_url;
      const avatarUrl = user.value.avatar_url;
      const avatarProperties = user.value.profile?.avatar_properties;
      if (!withList && avatarUrl && originalAvatarUrl) {
        openCropModal({
          file: originalAvatarUrl || avatarUrl,
          avatarProperties
        });
        return;
      }
      openAvatarModal({
        avatar: {
          url: avatarUrl,
          originUrl: originalAvatarUrl,
          avatarProperties
        }
      });
    }
  };
  const { open: openCropModal, close: closeCropModal } = useCropImageModal({
    onBack: () => {
      closeCropModal();
      openModal(true);
    },
    onUploaded: (data: User) => {
      onUpdateUser(data);
      closeCropModal();
    }
  });
  const { open: openAvatarModal } = useChooseAvatarModal({
    onSelect: openCropModal,
    onSuccess: (data: User) => {
      onUpdateUser(data);
    }
  });

  return {
    openCropModal,
    openAvatarModal,
    openModal
  };
};

export default useUpdateAvatar;
