<template>
  <div class="flex flex-col h-full w-full">
    <div class="mb-2.5 lg:mb-12 flex items-center lg:gap-4 gap-2.5 lg:hidden">
      <ui-back-link to="/dashboard/profile" />
      <ui-typography
        text="Coins"
        variant="h3"
        tag="h1"
      />
    </div>
    <ui-coming-soon />
  </div>
</template>

<script setup lang="ts">
  definePageMeta({
    layout: 'room'
  });

  useSeoMeta({
    title: 'Buy Coins'
  });
</script>
