<template>
  <ui-card
    padding="custom"
    :rounded="isMobile ? 'none' : 'xl'"
    class="flex gap-2.5 justify-between sm:border-card-placeholder-border border-transparent items-center relative py-2.5 pl-2.5 pr-5"
  >
    <div class="flex gap-2.5 items-center flex-1">
      <ui-avatar
        :src="user.avatar_url"
        :user-name="userName"
        size="sm"
        class="shrink-0 cursor-pointer"
        @click="goToProfile"
      />
      <div>
        <ui-typography
          variant="h6"
          tag="h6"
          class="cursor-pointer"
        >
          <ui-button
            variant="link"
            hide-space
            :to="routePath"
          >
            {{ userName }}
          </ui-button>
        </ui-typography>
        <ui-typography
          v-if="user.is_online"
          variant="small"
          class="text-primary"
          text="online"
        />
      </div>
    </div>
    <slot>
      <common-room-user-status
        v-if="status"
        :status="status"
      />
    </slot>
  </ui-card>
</template>

<script setup lang="ts">
  import type { TypedRouteLocationRaw } from '@typed-router';
  import type { TRoomUserStatus } from '~/globals/rooms';

  type Props = {
    user: RoomUser;
    status?: TRoomUserStatus;
  };
  const props = defineProps<Props>();
  const userName = useUserFullName(props, 'user');
  const isMobile = useIsLessThan('sm');
  const { routerPush } = useRouterLocale();
  const routePath = computed(() => ({
    name: 'profile-username',
    params: {
      username: props.user.username
    }
  }));
  const goToProfile = () => {
    routerPush(routePath.value as TypedRouteLocationRaw);
  };
</script>
