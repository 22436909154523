import type { ComponentInternalInstance, InjectionKey, UnwrapRef } from 'vue';

const expansionPanelsSymbol = Symbol.for('app:expansion-panels');
const expansionPanelSymbol = Symbol.for('app:expansion-panel');

function getItemIndex(items: UnwrapRef<GroupItem[]>, value: unknown) {
  const ids = getIds(items, [value]);

  if (!ids.length) return -1;

  return items.findIndex(item => item.id === ids[0]);
}

function getIds(items: UnwrapRef<GroupItem[]>, modelValue: any[]) {
  const ids: (number | string)[] = [];

  modelValue.forEach(value => {
    const item = items.find(item => isEqual(value, item.value));
    const itemByIndex = items[value];

    if (item?.value != null) {
      ids.push(item.id);
    } else if (itemByIndex != null) {
      ids.push(itemByIndex.id);
    }
  });

  return ids;
}

function getValues(items: UnwrapRef<GroupItem[]>, ids: any[]) {
  const values: unknown[] = [];

  ids.forEach(id => {
    const itemIndex = items.findIndex(item => item.id === id);
    if (~itemIndex) {
      const item = items[itemIndex];
      values.push(item.value != null ? item.value : itemIndex);
    }
  });

  return values;
}

function useGroup(props: GroupProps, injectKey: InjectionKey<GroupProvide> = expansionPanelsSymbol) {
  let isUnmounted = false;
  const groupVm = getCurrentInstance();
  const items = reactive<GroupItem[]>([]);

  const modelValue = useVModel(props, 'modelValue', undefined, {
    eventName: 'update:modelValue',
    passive: true
  });
  const selected = computed({
    get() {
      if (modelValue.value == null) {
        return [];
      }
      return getIds(items, wrapInArray(modelValue.value));
    },
    set(val) {
      const arr = getValues(items, val);
      modelValue.value = props.multiple ? arr : arr[0];
    }
  });

  function register(item: GroupItem, vm: ComponentInternalInstance) {
    // Is there a better way to fix this typing?
    const unwrapped = item as unknown as UnwrapRef<GroupItem>;

    const key = Symbol.for(`${injectKey.description}:id`);
    const children = findChildrenWithProvide(key, groupVm?.vnode);
    const index = children.indexOf(vm);

    if (index > -1) {
      items.splice(index, 0, unwrapped);
    } else {
      items.push(unwrapped);
    }
  }

  function unregister(id: number | string) {
    if (isUnmounted) return;

    // TODO: re-evaluate this line's importance in the future
    // should we only modify the model if mandatory is set.
    // selected.value = selected.value.filter(v => v !== id)

    forceMandatoryValue();

    const index = items.findIndex(item => item.id === id);
    items.splice(index, 1);
  }

  // If mandatory and nothing is selected, then select first non-disabled item
  function forceMandatoryValue() {
    const item = items.find(item => !item.disabled);
    if (item && props.mandatory === 'force' && !selected.value.length) {
      selected.value = [item.id];
    }
  }

  onMounted(() => {
    forceMandatoryValue();
  });

  onBeforeUnmount(() => {
    isUnmounted = true;
  });

  function select(id: number | string, value?: boolean) {
    const item = items.find(item => item.id === id);
    if (value && item?.disabled) return;

    if (props.multiple) {
      const internalValue = selected.value.slice();
      const index = internalValue.findIndex(v => v === id);
      const isSelected = ~index;
      value = value ?? !isSelected;

      // We can't remove value if group is
      // mandatory, value already exists,
      // and it is the only value
      if (isSelected && props.mandatory && internalValue.length <= 1) return;

      // We can't add value if it would
      // cause max limit to be exceeded
      if (!isSelected && props.max != null && internalValue.length + 1 > props.max) return;

      if (index < 0 && value) internalValue.push(id);
      else if (index >= 0 && !value) internalValue.splice(index, 1);

      selected.value = internalValue;
    } else {
      const isSelected = selected.value.includes(id);
      if (props.mandatory && isSelected) return;

      selected.value = (value ?? !isSelected) ? [id] : [];
    }
  }

  function step(offset: number) {
    // getting an offset from selected value obviously won't work with multiple values
    if (props.multiple) console.warn('This method is not supported when using "multiple" prop');

    if (!selected.value.length) {
      const item = items.find(item => !item.disabled);
      if (item) {
        selected.value = [item.id];
      }
    } else {
      const currentId = selected.value[0];
      const currentIndex = items.findIndex(i => i.id === currentId);

      let newIndex = (currentIndex + offset) % items.length;
      let newItem = items[newIndex];

      while (newItem.disabled && newIndex !== currentIndex) {
        newIndex = (newIndex + offset) % items.length;
        newItem = items[newIndex];
      }

      if (newItem.disabled) return;

      selected.value = [items[newIndex].id];
    }
  }

  const state: GroupProvide = {
    register,
    unregister,
    selected,
    select,
    disabled: toRef(props, 'disabled'),
    prev: () => step(items.length - 1),
    next: () => step(1),
    isSelected: (id: number | string) => selected.value.includes(id),
    selectedClass: computed(() => props.selectedClass),
    items: computed(() => items),
    getItemIndex: (value: unknown) => getItemIndex(items, value)
  };

  provide(injectKey, state);

  return state;
}

function useGroupItem(
  props: GroupItemProps,
  injectKey?: InjectionKey<GroupProvide>,
  required?: true,
  injectPanelKey?: InjectionKey<GroupItemProvide>
): GroupItemProvide;
function useGroupItem(
  props: GroupItemProps,
  injectKey?: InjectionKey<GroupProvide>,
  required?: false,
  injectPanelKey?: InjectionKey<GroupItemProvide>
): GroupItemProvide | null;
function useGroupItem(
  props: GroupItemProps,
  injectKey: InjectionKey<GroupProvide> = expansionPanelsSymbol,
  required = true,
  injectPanelKey: InjectionKey<GroupItemProvide> = expansionPanelSymbol
): GroupItemProvide | null {
  const vm = getCurrentInstance();

  if (!vm) {
    throw new Error('useGroupItem composable must be used inside a component setup function');
  }

  const id = useId();

  provide(Symbol.for(`${injectKey.description}:id`), id);

  const group = inject(injectKey, null);

  if (!group) {
    if (!required) return group;

    throw new Error(`Could not find useGroup injection with symbol ${injectKey.description}`);
  }

  const value = toRef(props, 'value');
  const disabled = computed(() => !!(group.disabled.value || props.disabled));

  group.register(
    {
      id,
      value,
      disabled
    },
    vm
  );

  onBeforeUnmount(() => {
    group.unregister(id);
  });

  const isSelected = computed(() => {
    return group.isSelected(id);
  });

  const selectedClass = computed(() => isSelected.value && [group.selectedClass.value, props.selectedClass]);

  watch(isSelected, value => {
    vm.emit('group:selected', { value });
  });

  const state = {
    id,
    isSelected,
    toggle: () => group.select(id, !isSelected.value),
    select: (value: boolean) => group.select(id, value),
    selectedClass,
    value,
    disabled,
    group
  };

  provide(injectPanelKey, state);

  return state;
}

const useGroupItemState = (injectKey = expansionPanelSymbol) => {
  const item = inject<GroupItemProvide>(injectKey);

  if (!item) {
    throw new Error(`Could not find useGroup injection with symbol ${injectKey.description}`);
  }
  return item;
};

export { useGroup, useGroupItem, useGroupItemState, expansionPanelsSymbol, expansionPanelSymbol };
