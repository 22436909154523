<template>
  <div
    v-if="isVertical || line"
    class="items-end grid-cols-10 grid gap-1.5"
    :class="{ 'h-8': isVertical }"
  >
    <div
      v-for="count in maxCount"
      :key="count"
      :class="[calcLevel >= count ? colorLevel : 'bg-gray', { 'h-1 rounded': !isVertical }]"
      :style="isVertical ? { height: count * maxCount + '%' } : ''"
    />
  </div>
  <div
    v-else
    class="bg-gray rounded h-2 overflow-hidden"
    :style="{
      width: `calc(${level} * 100%)`
    }"
  >
    <span
      :class="colorLevel"
      class="flex h-full"
    />
  </div>
</template>

<script setup lang="ts">
  type Props = {
    level: number;
    isVertical?: boolean;
    line?: boolean;
    multiColors?: boolean;
    maxCount?: number;
  };

  const props = withDefaults(defineProps<Props>(), {
    maxCount: 10
  });
  const calcLevel = computed(() => {
    if (props.level < 0.1 && props.level > 0.01) {
      return 1;
    }
    return props.level * props.maxCount;
  });
  const colorLevel = computed(() => {
    if (props.multiColors) {
      if (calcLevel.value < 2) {
        return 'bg-error';
      }
      if (calcLevel.value < 4) {
        return 'bg-warning';
      }
    }

    return 'bg-success';
  });
</script>
