<template>
  <div class="lg:max-w-[360px] w-full lg:pr-7 pr-0">
    <div class="mb-2.5 lg:mb-12 flex items-center lg:gap-4 gap-2.5 lg:hidden">
      <ui-back-link to="/dashboard/profile" />
      <ui-typography
        text="Settings"
        variant="h3"
        tag="h1"
      />
    </div>
    <ui-card class="grid grid-cols-1 sm:gap-7 gap-5 pt-5">
      <ui-breadcrumbs
        v-if="breadcrumbs.length > 1"
        :items="breadcrumbs"
        class="lg:hidden block"
      />
      <div class="flex sm:flex-col flex-row sm:gap-1.5 gap-2.5">
        <common-avatar-update
          size="md"
          class="sm:mx-auto mx-0 grow-0"
        />
        <common-profile-user-information
          v-if="user"
          :user="user"
          :user-name="userName"
          centered
        />
      </div>
      <div class="flex flex-col divide-y divide-card-placeholder-border">
        <div
          v-for="(group, index) in navigations"
          :key="index"
          class="grid grid-cols-1 gap-5 py-5 last:pb-0 first:pt-0"
        >
          <ui-button
            v-for="(item, innerIndex) in group"
            :key="index + '' + innerIndex"
            size="lg"
            v-bind="item.linkProps"
            :variant="item.linkProps?.variant || 'link'"
            hide-space
            direct="left"
          />
        </div>
      </div>
    </ui-card>
  </div>
</template>

<script setup lang="ts">
  const user = useSanctumUser<User>();
  const apiRoutes = useApiRoutes();
  const [onLogout] = useLogout();
  const { routerPush } = useRouterLocale();
  const { open: openDeleteModal } = useConfirmModal({
    title: 'Do you really want to delete your account?',
    confirmText: 'Yes, Delete',
    confirmAction: async () => {
      await apiRoutes.profile.delete();
      return true;
    },
    onSuccess() {
      user.value = null;
      routerPush({ name: 'login' });
    }
  });

  const userName = useCurrentUserName();
  const breadcrumbs = useDashboardSettingsBreadcrumbs();
  const navigations = computed<NavLink[][]>(() => [
    [
      {
        linkProps: {
          text: 'General',
          to: { name: 'dashboard-settings-general' }
        }
      },
      {
        linkProps: {
          text: 'Password',
          to: { name: 'dashboard-settings-password' }
        }
      },
      {
        linkProps: {
          text: 'Social Profiles',
          to: { name: 'dashboard-settings-social-profiles' }
        }
      }
    ],
    [
      {
        linkProps: {
          text: 'Support',
          to: { name: 'dashboard-settings-support' }
        }
      }
    ],
    [
      {
        linkProps: {
          text: 'Privacy Policy',
          to: { name: 'dashboard-settings-privacy-policy' }
        }
      },
      {
        linkProps: {
          text: 'Refund Policy',
          to: { name: 'dashboard-settings-refund-policy' }
        }
      },
      {
        linkProps: {
          text: 'Terms of Use Agreement',
          to: { name: 'dashboard-settings-terms' }
        }
      }
    ],
    [
      {
        linkProps: {
          text: 'Log Out',
          onClick: () => onLogout()
        }
      },
      {
        linkProps: {
          text: 'Delete Account',
          onClick: () => openDeleteModal(),
          variant: 'error-link'
        }
      }
    ]
  ]);
</script>
