<template>
  <div class="flex items-center gap-2.5">
    <ui-typography
      text="Online"
      class="text-primary"
    />
    <ui-fields-switch
      v-tooltip="$t('Toggle online status')"
      :disabled="loading"
      :model-value="isVisibleOnline"
      :checked-value="true"
      active-color
      :unchecked-value="false"
      sync-v-model
      enabled-controlled
      @update:model-value="onToggleOnline"
    />
    <ui-spinner
      v-if="loading"
      class="shrink-0 !w-4 !h-4"
    />
  </div>
</template>

<script setup lang="ts">
  const { loading, handler } = useHandlerErrorAndLoadingClient();
  const { user, onUpdateUser } = useAuthUser();
  const apiRoutes = useApiRoutes();
  const isVisibleOnline = computed(() => user.value?.profile?.is_visible_online);

  const onToggleOnline = async () => {
    if (user.value) {
      const data = {
        profile: { is_visible_online: !isVisibleOnline.value }
      };
      const { isError } = await handler(apiRoutes.profile.update(data));
      if (!isError) {
        onUpdateUser(data);
      }
    }
  };
</script>
