<template>
  <component
    :is="titleTag"
    :class="classes"
    :disabled="disabled"
    :type="type"
    :tabindex="disabled ? -1 : tabindex"
    v-bind="$attrs"
    @keydown.enter="onTogglePanel"
    @click="onTogglePanel"
  >
    <slot v-bind="state" />
    <span
      v-if="!hideActions"
      class="inline-flex items-center"
    >
      <slot
        name="actions"
        v-bind="state"
        :toggle="toggle"
      >
        <ui-icon
          class="items-center justify-center transition-all text-sm md:text-lg"
          :name="currentIcon"
        />
      </slot>
    </span>
  </component>
</template>

<script setup lang="ts">
  import { cva } from 'class-variance-authority';

  defineOptions({
    inheritAttrs: false
  });

  const variantClasses = cva('flex items-center flex-wrap gap-1', {
    variants: {
      size: {
        md: 'h3'
      }
    }
  });

  const props = withDefaults(defineProps<GroupItemTitleProps>(), {
    tabindex: '1',
    titleTag: 'button',
    type: 'button',
    size: 'md',
    expandIcon: 'expand_more',
    expandIconActive: 'expand_less'
  });

  const attrs = useAttrs();

  const classes = computed(() => {
    return cn(
      variantClasses({
        size: props.size
      }),
      attrs.class as ClassesType
    );
  });

  const { isSelected, disabled, toggle } = useGroupItemState();

  const state = computed(() => ({
    expanded: isSelected.value,
    disabled: disabled.value,
    expandIcon: props.expandIcon,
    onToggle: toggle
  }));

  const currentIcon = computed(() => (isSelected.value ? props.expandIconActive : props.expandIcon));

  const onTogglePanel = () => {
    if (!props.disabled) {
      toggle();
    }
  };
</script>
