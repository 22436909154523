<template>
  <ui-card
    class="grid sm:gap-2.5 gap-1 2xl:grid-cols-7 lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-4 grid-cols-3 relative"
  >
    <common-profile-media-card
      v-for="(attachment, index) in attachments"
      :key="attachment.id"
      :attachment="attachment"
      @click="openMediaPreviewModal(index)"
    />
  </ui-card>
</template>

<script setup lang="ts">
  type Props = { attachments: Attachment[] };
  const props = defineProps<Props>();

  const { open } = useMediaPreviewModal({});

  const openMediaPreviewModal = (initIndex: number) => {
    open({
      initIndex,
      attachments: props.attachments
    });
  };
</script>
