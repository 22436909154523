<template>
  <ui-fields-control
    v-bind="props"
    rounded="full"
    keep-value-on-unmount
    hide-error
  >
    <template #control="{ inputId, checked }">
      <label
        :for="inputId"
        :aria-disabled="disabled"
        :data-ui="checked && 'checked'"
        :class="labelClasses"
      >
        <span
          v-if="loading"
          class="absolute inset-0 flex items-center justify-center"
        >
          <ui-spinner />
        </span>
        <ui-typography
          :class="{ invisible: loading }"
          variant="caption"
          class="flex items-center justify-center gap-1"
        >
          {{ title }}
          <ui-icon
            v-show="disabled"
            name="close"
            class="cursor-pointer"
            @click="onDelete"
          />
        </ui-typography>
      </label>
    </template>
  </ui-fields-control>
</template>

<script setup lang="ts">
  type Props = Omit<ControlField, 'keepValueOnUnmount'> & { title: string };
  const props = defineProps<Props>();

  const apiRoutes = useApiRoutes();
  const { onDeleteItem } = useInterestsList();
  const { handler, loading } = useHandlerErrorAndLoadingClient();

  const onDelete = async () => {
    const res = await handler(apiRoutes.interests.delete(props.checkedValue as number));
    if (!res.isError) {
      onDeleteItem(props.checkedValue as number);
    }
  };

  const labelClasses = computed(() => {
    return cn(
      'py-2 px-4 border-gray bg-gray text-body-text flex border rounded-lg data-checked:text-white data-checked:bg-primary data-checked:border-primary aria-disabled:!bg-primary/80 aria-disabled:!border-primary/80 relative cursor-pointer',
      loading.value && 'aria-disabled:!bg-disabled aria-disabled:!text-white'
    );
  });
</script>
