<template>
  <ui-card
    :loading="pending"
    :error="error"
    class="grid sm:gap-2.5 gap-1 2xl:grid-cols-7 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-4 grid-cols-3 relative"
  >
    <ui-fields-file-upload
      hide-preview
      class="justify-center"
      :disabled="disabledUpload"
      @select="onOpenUploadMediaModal"
    >
      <template #default="{ inputId, loading }">
        <ui-card
          v-tooltip="disabledUpload ? $t('Plan Limit Reached') : null"
          padding="custom"
          color="placeholder"
          rounded="sm"
          :active="!disabledUpload"
          class="w-full h-full flex flex-col justify-center gap-2.5 text-center border border-dashed border-secondary relative overflow-hidden text-primary"
        >
          <ui-icon
            name="add_a_photo"
            :class="{ 'text-gray-300': disabledUpload }"
          />
          <ui-button
            variant="link"
            size="lg"
            full
            hide-space
            :disabled="disabledUpload"
            underline="always"
            text="Add Photo"
            :for="inputId"
            :loading="loading"
            tag="label"
          />
        </ui-card>
      </template>
    </ui-fields-file-upload>
    <common-profile-media-card
      v-for="attachment in attachments"
      :key="attachment.id"
      :attachment="attachment"
      private
      @remove="onRemoveAttachment"
      @click="openMediaPreviewModal(attachment)"
    />
    <ui-card
      v-for="i in placeholderItems"
      :key="i + 'placeholder'"
      color="placeholder"
      rounded="sm"
      class="w-full pt-[100%] border-secondary border-dashed border"
      padding="custom"
    />
  </ui-card>
</template>

<script setup lang="ts">
  const apiRoutes = useApiRoutes();
  const viewport = useViewport();
  const { user } = useAuthUser();

  const {
    data: attachments,
    pending,
    error
  } = await useAsyncData<Attachment[]>(
    async () => {
      const res = await apiRoutes.attachments.index();
      return res._data.data;
    },
    {
      default: () => []
    }
  );

  const disabledUpload = computed(() => {
    const limit = user.value?.subscription_plan.limitations.profile_photo_limit;
    if (limit === null) {
      return false;
    }
    return !!(limit && limit <= attachments.value.length);
  });

  const placeholderItems = computed(() => {
    const breakpoint = viewport.breakpoint;
    const columns =
      {
        '2xl': 7,
        xl: 6,
        lg: 5,
        md: 5,
        sm: 4
      }[breakpoint.value] || 3;
    const attachmentsLength = (attachments.value?.length || 0) + 1;
    const itemsCount = columns - (attachmentsLength % columns);
    if (itemsCount === columns) {
      return 0;
    }
    return itemsCount;
  });

  const onRemoveAttachment = (id: number) => {
    attachments.value = removeItemArray(attachments.value, id);
  };

  const onUpdateMedia = (attachment: Attachment) => {
    attachments.value = updateItemArray(attachments.value, attachment);
  };

  const onAddMedia = (attachment: Attachment) => {
    attachments.value = [...attachments.value, attachment];
  };
  const { openCropModal } = useUpdateAvatar();

  const { open: openUploadMediaModal, close: closeUploadMediaModal } = useUploadMediaModal({
    onSuccess: (attachment: Attachment) => {
      onAddMedia(attachment);
      closeUploadMediaModal();
    },
    onSetAvatar: (file: File) => {
      openCropModal({ file });
      closeUploadMediaModal();
    }
  });

  const onOpenUploadMediaModal = (file: File) => {
    if (file) {
      openUploadMediaModal({ file, create: true });
    }
  };

  const { open, close } = useUpdatePreviewMediaModal({
    onSuccess: (attachment: Attachment) => {
      onUpdateMedia(attachment);
      close();
    },
    onSetAvatar: (file: string) => {
      openCropModal({ file });
      close();
    }
  });

  const openMediaPreviewModal = (attachment: Attachment) => {
    open({
      attachment,
      attachments
    });
  };
</script>
