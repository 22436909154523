<template>
  <div class="w-full">
    <ui-loader
      :loading="pending"
      :error="error"
      static
    >
      <div
        v-if="user"
        class="grid grid-cols-1 gap-2.5 sm:gap-5 lg:gap-7"
      >
        <common-profile-main-info
          :user="user"
          is-public
        />
        <common-profile-media-gallery
          v-if="attachments.length"
          :attachments="attachments"
        />
        <lazy-common-profile-additional-info
          :user="user"
          :interests="interestsStore.profilePrivateInterests"
        />
      </div>
    </ui-loader>
  </div>
</template>

<script setup lang="ts">
  const apiRoutes = useApiRoutes();
  const route = useRoute('profile-username');
  definePageMeta({
    layout: 'room',
    middleware: ['sanctum:auth']
  });

  const interestsStore = useInterestsStore();

  const {
    pending,
    error,
    data: user
  } = await useAsyncData<User>(async () => {
    const [user] = await Promise.all([
      apiRoutes.profiles.show(route.params.username),
      interestsStore.fetchDefaultInterests()
    ]);

    return user._data.data;
  });

  const attachments = computed(() => user.value?.attachments || []);

  // useSeoMeta({
  //   title: user.value?.username + ' | Profile'
  // });
</script>
