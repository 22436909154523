<template>
  <ui-card
    v-if="infoConfig.length"
    class="grid grid-cols-1 gap-2.5 sm:gap-5"
  >
    <div
      v-for="(item, i) in infoConfig"
      :key="i"
      class="grid grid-cols-1 gap-2.5"
    >
      <ui-typography
        variant="h3"
        tag="h3"
        class="text-primary"
      >
        {{ item.name }}
      </ui-typography>

      <component
        :is="item.valueComponent"
        v-bind="item.valueComponentProps"
      />
      <p v-if="item.value">{{ item.value }}</p>
    </div>
  </ui-card>
</template>

<script setup lang="ts">
  import { CommonProfileSocials, CommonProfileInterestsList } from '#components';

  type Props = { user: User; interests?: Interest[] };
  const props = defineProps<Props>();

  const infoConfig = computed<ProfileInfoItem[]>(() => {
    const items = [];

    if (props.user.profile?.about) {
      items.push({ name: 'About Me', value: props.user.profile.about });
    }

    if (props.user.interest_ids?.length) {
      items.push({
        name: 'My Interests',
        valueComponent: CommonProfileInterestsList,
        valueComponentProps: { data: props.interests || [], user: props.user }
      });
    }

    if (props.user.profile?.social_links && Object.values(props.user.profile.social_links).some(Boolean)) {
      items.push({
        name: 'My Social Profiles',
        valueComponent: CommonProfileSocials,
        valueComponentProps: { links: props.user.profile.social_links }
      });
    }

    return items;
  });
</script>
